import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  FormInput,
  InputCardNumber,
  InputHidden,
  InputMoney,
  InputPhone,
  SelectCompt,
  InputContainer,
} from "../index";
import cls from "./Form1.module.scss";
import { checkLuhn } from "../../utils/helpers/cardValidateLuhn";
import {
  GoogleReCaptcha, GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';


export const Form1 = (props) => {
  const {
    item,
    defaultFormFields,
    onChangeStage,
    formContextProp,
    inputStyle,
    shop,
    captchaToken,
    checkCaptchaAsync,
  } = props;
  const { payment_sources } = item;
  const { formData, setFormData } = useContext(formContextProp);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [mail, setMail] = useState("");
  const [paymentOption, setPaymentOption] = useState(payment_sources[0].value);
  const [commission, setCommission] = useState(5);
  const [minAmount, setMinAmount] = useState(1200);
  const [maxAmount, setMaxAmount] = useState(999999);
  const [isKP, setIsKp] = useState(false)
  const [currency, setCurrency] = useState("RUB");
  const [cardValid, setCardValid] = useState(true);
  const [buttonDisable, setButtonDisable] = useState(true);


  const onChangeForm = (e, label) => {
    const { name, value } = e.target;

    if (name === "account.value.persacc" && label !== "Лицевой счёт") {
      setCardValid(checkLuhn(value));
    } else if (label === "Лицевой счёт") {
      setCardValid(value === "424242");
    }

    setFormFields({ ...formFields, [name]: value });


  };

  const handleVerify = () => {
    // console.log('ok')
  }


  const onChangePaymentOption = (
    option,
    commission,
    min,
    max,
    currency = "RUB",
  ) => {
    setPaymentOption(option);
    setCommission(commission);
    setMinAmount(min);
    setMaxAmount(max);
    setCurrency(currency);
    if (option === 'card_kr') {
      setIsKp(true)
    }
  };


  const InputsArray = item.data.requiredFields.map((inputData) => {

    switch (inputData.keyboardType) {
      case "NUMBER":
        return (
          <>
            <InputCardNumber
              key={inputData.fieldId}
              formFields={formFields}
              onChange={(e) => onChangeForm(e, inputData.label_ru)}
              inputData={inputData}
              style={inputStyle}
              error={!cardValid}
            />
          </>
        );
      case "MONEY":
        return (
          <InputMoney
            key={inputData.fieldId}
            formFields={formFields}
            onChange={onChangeForm}
            inputData={inputData}
            min={minAmount}
            max={maxAmount}
            itemData={item.data}
            style={inputStyle}
          />
        );
      case "TEXT":
        return (
          <InputHidden
            key={inputData.fieldId}
            formFields={formFields}
            setFormFields={setFormFields}
            inputData={inputData}
          />
        );
      case "PHONE":
        return (
          <InputPhone
            key={inputData.fieldId}
            formFields={formFields}
            onChange={onChangeForm}
            inputData={inputData}
            style={inputStyle}
          />
        );
      default:
        return "";
    }
  });

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      await checkCaptchaAsync()
    }


    const commissionValue = (formFields.total * commission).toFixed(2);

    const newFormData = {
      ...formData,
      service_id: item.data.id,
      payment_option: paymentOption,
      mail,
      commission: commissionValue,
      amount: (Number(formFields.total) + Number(commissionValue)).toString(),
      user_ref: uuidv4(),
      shop,
      currency,
      fields: { ...formFields },
    };
    setFormData(newFormData);

    onChangeStage(); // Переключение на следующий этап
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    cardValid ? setButtonDisable(false) : setButtonDisable(true);
  }, [cardValid]);

  return (

    <form onSubmit={onSubmitForm}>
      <div className={cls.inputsWrap}>
        <SelectCompt
          options={payment_sources}
          paymentOption={paymentOption}
          changeOption={onChangePaymentOption}
          style={inputStyle}
        />

        {InputsArray}

        <InputContainer>
          <label htmlFor={"mail"}>Электронный адрес для чека</label>
          <FormInput
            style={inputStyle}
            name="mail"
            placeholder="name@mail.com"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        </InputContainer>
        <div className={cls.description}>
          <p>Валюта: {currency}</p>
          <p>
            Минимальная сумма: {minAmount} {currency}
          </p>
          <p>
            Максимальная сумма: {maxAmount} {currency}
          </p>
          <p>Комиссия: {(commission * 100).toFixed(1)}%</p>
        </div>
        <GoogleReCaptcha onVerify={handleVerify} />
        <Button type="submit" disabled={buttonDisable}>
          Далее
        </Button>

      </div>
    </form>
  );
};
