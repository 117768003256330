import InputMask from "react-input-mask";
import {InputContainer} from "../UI/InputContainer/InputContainer";
import { Input, Tooltip } from "antd";

export const InputCardNumber = (props) => {
  const { inputData, formFields, onChange, style, error, ...otherProps } = props;
  const {fieldId: name, label_ru} = inputData
  const inputStyle = error ? `${style.FormInput} ${style.error}`
    : style.FormInput

  const value = formFields[name.toString()];

  const mask = inputData.inputMask?.replace(/[0-9]/g, "9").replace(/-/g, " ");
  return (
    <InputContainer>
      <label htmlFor={name}>{label_ru}</label>

      <InputMask
        className={inputStyle}
        id={name}
        name={name}
        placeholder={label_ru || "Номер карты"}
        value={value}
        onChange={onChange}
        mask={mask}
        maskChar={null}
        required
        {...otherProps}
      >
        </InputMask>
    </InputContainer>
  );
};
